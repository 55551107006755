<template>
    <div class="price-negotiations">

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Перечень отчетов согласования цен</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="outline-primary"
                    @click="onClickExport"
                >
                  <span>Экспорт</span>
                </BButton>
            </div>

        </div>

        <TableFilter
            :columns="positionsFields"
            @update-selected-columns="columns = $event"
            @search="onSearch"
            @clear="clearFilterData"
            @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
        />

        <DefaultTable
            :data="filteredNegotiations"
            :fields="positionsFieldsFiltered"
            :loading="loading"
            emptyText="Перечень отчетов согласования цен пуст"
            :noLocalSorting="true"
            @sort-change="sorting"
            @pagination-change="paginationChange"
            @per-page-change="perPageChange"
        />

        <FilterSidebar
            id="filter"
            v-model="filterData"
            @apply="updateDataWithFilters"
        />

    </div>
</template>

<script>

import TableFilter from "@/components/TableFilter"
import DefaultTable from "@/components/Tables/Default"
import FilterSidebar from "@/components/FilterSidebar"
import { mapGetters, mapState } from 'vuex'

function initialFilterData() {
    return {
        organization_status: ''
    }
}

export default {
    name: "PriceNegotiationList",
    components: {
        TableFilter,
        DefaultTable,
        FilterSidebar
    },
    computed: {
        ...mapState({
            negotiations: state => state.priceNegotiationsReport.negotiationsReport
        }),
        positionsFieldsFiltered () {
            let res = []
            this.positionsFields.forEach(item => {
                if (this.columns.filter(col => col === item.label).length) {
                    res.push(item)
                }
            })
            return res.length > 0 ? res : this.positionsFields
        },
        ...mapGetters( {
            isProvider: 'isProvider',
            isContractor: 'isContractor'
        } ),
        filteredNegotiations() {
            return this.negotiations
        }
    },
    data: () => ({
        positionsFields: [
            {
                key: 'id',
                sortable: true,
                label: '№ нумерация в списке',
                full: '№ нумерация в списке',
                openDefault: true
            },
            {
                key: 'report-price-number',
                sortable: true,
                label: '№ запроса',
                full: 'Номер отчета',
                openDefault: true
            },
            {
                key: 'report-mnemocode',
                sortable: true,
                label: 'Мнемокод',
                full: 'Мнемокод',
                openDefault: true
            },
            {
                key: 'report-name',
                sortable: true,
                label: 'Полное наименование МТР',
                full: 'Полное наименование МТР',
                openDefault: true
            },
            {
                key: 'report-unit',
                sortable: true,
                label: 'ЕИ (единица измерения)',
                full: 'ЕИ (единица измерения)',
                openDefault: true
            },
            {
                key: 'report-date',
                sortable: true,
                label: 'Дата подачи запроса',
                full: 'Дата подачи запроса',
                openDefault: true
            },
            {
                key: 'report-status',
                sortable: true,
                label: 'Точка маршрута (статус)',
                full: 'Точка маршрута (статус)',
                openDefault: true
            },
            {
                key: 'report-provider',
                sortable: true,
                label: 'Поставщик',
                full: 'Поставщик',
                openDefault: true
            },
            {
                key: 'report-days',
                sortable: true,
                label: 'Общее количество дней нахождения заявки на согласовании',
                full: 'Общее количество дней нахождения заявки на согласовании',
                openDefault: true
            },
            {
                key: 'date-agreement',
                sortable: true,
                label: 'Дата согласования',
                full: 'Дата согласования',
                openDefault: true
            }
            // {
            //     key: 'report-nomenclature',
            //     sortable: true,
            //     label: 'Номенклатура',
            //     full: 'Номенклатура',
            //     openDefault: true
            // },
            // {
            //     key: 'report-current-price',
            //     sortable: true,
            //     label: 'Текущая цена без НДС',
            //     full: 'Текущая цена без НДС',
            //     openDefault: true
            // },
            // {
            //     key: 'report-new-price',
            //     sortable: true,
            //     label: 'Новая цена без НДС',
            //     full: 'Новая цена без НДС',
            //     openDefault: true
            // },
            // {
            //     key: 'creation_date',
            //     sortable: true,
            //     label: 'Дата создания',
            //     full: 'Дата создания',
            //     openDefault: true
            // }
        ],
        importFile: null,
        loading: true,
        columns: [],
        filter: '',
        filterData: initialFilterData(),
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Перечень согласования цен', link: '/price-negotiations' },
            { title: 'Перечень отчетов согласования цен', link: '/price-negotiations-report' }
        ],
        sortingData: {
            sort_field: '',
            sort_order: '',
            page: '',
            per_page: 25
        }
    }),
    methods: {
        async getData () {
            this.loading = true
            try {
                await this.$store.dispatch('getNegotiationsReport')
            } finally {
                this.loading = false
            }
        },
        sendImport () {
            if (this.importFile !== null) {
                this.$bvModal.show('import-success')
                this.$bvModal.hide('import')
            } else {
                this.$bvToast.toast('Вам необходимо загрузить файл формата XML', {
                    title: 'Вы не прикрепили файл для импорта',
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
            }
        },
        clearFilterData () {
            this.filterData = initialFilterData()
            this.getData()
        },
        updateDataWithFilters () {
            const params = {
                ...this.filterData,
                ...this.sortingData
            }
            this.loading = true
            this.$store.dispatch('getNegotiationsReport', params).finally(() => {
                this.loading = false
            })
        },
        sorting (event) {
            this.sortingData.sort_field = event.sortBy,
            this.sortingData.sort_order = event.sortDesc ? 'desc' : 'asc'
            this.updateDataWithFilters()
        },
        paginationChange (page) {
            this.sortingData.page = page
            this.updateDataWithFilters()
        },
        perPageChange (perPage) {
            this.sortingData.page = ''
            this.sortingData.per_page = perPage
            this.updateDataWithFilters()
        },
        async onClickExport() {
            const baseURL = (
                ( process.env.NODE_ENV === 'production' )
                    ? process.env.VUE_APP_API_URL
                    : process.env.VUE_APP_API_DEV_URL
            )
            const token = this.$keycloak.token

            const url = baseURL + 'price-negotiations/report/download'
            const options = {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
            const response = await fetch( url, options )
            const blob = await response.blob()

            const target = window.URL.createObjectURL( blob )
            window.open( target )
        },
        async onSearch( event ) {
            this.filter = event
            this.filterData.search = event
            
            this.updateDataWithFilters()
        }
    },
    mounted () {
        this.getData()
            .then( () => {
                if ( this.isProvider )
                    this.positionsFields = this.positionsFields.filter( el => el.key !== 'contractor' )
            } )
        this.positionsFields.forEach(item => {
            if (item.openDefault) {
                this.columns.push(item.label)
            }
        })
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
